<template>
  <div class="ddj-country">
    <!-- <div class="main-Title bgff"><h2>Country</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-form-item label="Country:" label-width="70px">
                <el-select
                  v-model="filter.countryCode"
                  placeholder="Please select"
                  class="w100"
                  filterable
                >
                  <el-option value="" label="---ALL---"></el-option>
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="3">
              <el-button
                type="primary"
                size="mini"
                :loading="loading.list"
                @click="getList('filter')"
                >Search</el-button
              >
              <!--              <el-button type="primary" size="mini" @click="add()">Add</el-button>-->
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100 mb10"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column
            label="国家代码"
            prop="countryCode"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="国家名称" prop="countryName" width="300"></el-table-column>
          <el-table-column label="IOS config" prop="speedIos"></el-table-column>
          <el-table-column label="AOS config" prop="speedAos"></el-table-column>
          <!--          <el-table-column label="操作" width="100" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjCountryCon from '../../../controllers/DDJ/v3/country';
  export default {
    name: 'ddjCountry',
    ...ddjCountryCon,
  };
</script>

<style></style>
